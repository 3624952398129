<template>
  <section class="main-container">
    <div class="container-title">
      <p class="text-3xl font-bold mb-2">Program Unggulan</p>
      <p class="text-green-800">
        SDIT Al Madinah Pontianak memiliki berbagai program unggulan sebagai
        berikut.
      </p>
    </div>
    <!-- List -->
    <div class="sub-container" v-if="news.length > 0">
      <div class="sub-container__item" v-for="(item, key) in news" :key="key">
        <div class="bg-white relative mx-auto z-10">
          <img
            :src="item.thumbnail_url_full"
            :alt="`Gambar ${item.title}`"
            class="shadow w-full"
            style="min-height: 150px"
          />
          <div
            class="flex items-center absolute text-center bottom-0 left-0 p-4 bg-gray-800 bg-opacity-80 h-full text-white w-full"
          >
            <section class="text-center w-full p-4">
              <p class="font-bold capitalize mb-1 text-xl md:text-2xl">
                {{ item.title }}
              </p>
              <router-link class="text-gray-100 block" :to="`/news/${item.id}`">
                <span>Selengkapnya</span>
              </router-link>
            </section>
          </div>
        </div>
      </div>

      <!-- Prevent Empty space when the list item count is odd -->
      <div
        class="sub-container__item odd-resolver min-h-full relative bg-gray-900"
        v-if="news.length % 2 === 1"
      >
        <div
          class="flex items-center absolute text-center bottom-0 left-0 p-4 bg-gray-800 bg-opacity-80 h-full text-white w-full"
        >
          <section class="text-center w-full p-4">
            <fa-icon icon="ellipsis-h" class="text-xl"></fa-icon>
          </section>
        </div>
      </div>
    </div>
    <div v-else class="sub-container gap-2 pt-8 px-2">
      <svg
        v-for="i in [1, 2]"
        :key="`placeholder-${i}`"
        role="img"
        width="320"
        height="124"
        aria-labelledby="loading-aria"
        viewBox="0 0 320 124"
        preserveAspectRatio="none"
      >
        <title id="loading-aria">Loading...</title>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clip-path="url(#clip-path)"
          style="fill: url('#fill')"
        ></rect>
        <defs>
          <clipPath id="clip-path">
            <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
            <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
            <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
            <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
            <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
            <circle cx="20" cy="20" r="20" />
          </clipPath>
          <linearGradient id="fill">
            <stop offset="0.599964" stop-color="#d7d7d7" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
            <stop offset="2.59996" stop-color="#d7d7d7" stop-opacity="1">
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              ></animate>
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      news: [],
    };
  },
  methods: {
    async fetchNews() {
      let response = await axios.get("api/news?filter=highlighted");
      this.news = response.data.news;
    },
  },
  mounted() {
    this.fetchNews();
  },
  name: "NewsHighlight",
};
</script>

<style lang="scss" scoped>
.main-container {
  overflow-x: clip;

  @apply relative pb-10 px-4 md:px-12;
}

.container-title {
  margin-top: -12.25rem;

  @screen sm {
    margin-top: -9.3rem;
  }

  @apply bg-green-50 max-w-screen-lg container mx-auto text-center;
  @apply py-10 px-4 shadow rounded-t-lg;
}

.sub-container {
  @apply relative max-w-screen-lg mx-auto container;
  @apply bg-white flex flex-wrap justify-start shadow rounded-b-lg;
}

.sub-container__item {
  &.odd-resolver {
    @apply hidden;
  }

  @screen md {
    &.odd-resolver {
      @apply block;
    }

    max-width: 50%;
  }

  @apply w-full;
}
</style>